import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    redirect:'/home',
    component: () => import('@/views/index.vue'),
    children:[
      {
        path:"/home",
        name:'新芽教育',
        component: () => import('@/views/index/home.vue'),
        meta:{
          title: '新芽教育'
        },
      },
      {
        path:"/product",
        name:'应用产品中心',
        redirect:'/product/page1',
        component: () => import('@/views/product/index.vue'),
        meta:{
          title: '应用产品中心'
        },
        children:[
          {
            path:"/product/page1",
            name:'移动App应用',
            component: () => import('@/views/product/page1.vue'),
          },
          {
            path:"/product/page2",
            name:'智能终端应用',
            component: () => import('@/views/product/page2.vue'),
          },
          {
            path:"/product/page3",
            name:'电视端应用',
            component: () => import('@/views/product/page3.vue'),
          },
          {
            path:"/product/page4",
            name:'pad应用',
            component: () => import('@/views/product/page4.vue'),
          },
        ],
      },
      {
        path:"/resource",
        name:'资源内容中心',
        component: () => import('@/views/resource/index.vue'),
        meta:{
          title: '资源内容中心'
        },
      },
      {
        path:"/aboutUs",
        name:'关于我们',
        component: () => import('@/views/aboutUs/index.vue'),
        meta:{
          title: '关于我们'
        },
        
      },
    ]

  },
  {
    path:"/questionnaire",
    name:'questionnaire',
    component:()=> import("@/views/questionnaire/index.vue"),
    meta:{
      title: '调查问卷'
    },
  },
  {
    path:"/getMeber",
    name:'getMeber',
    component:()=> import("@/views/questionnaire/register.vue"),
    meta:{
      title: '领取会员'
    },
  },
  {
    path:"/rule",
    name:'rule',
    component:()=> import("@/views/questionnaire/rule.vue"),
    meta:{
      title: '活动规则'
    },
  }
];

const router = createRouter({
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
